<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_green_logo_horizontal.svg"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen"  style="height:80vh;">
      <div class="m-auto">
        <img
          src="@/assets/images/relatus_green_logo.svg"
          class="m-auto"
          style="height: 150px;"
        >

        <div style="margin-top: 60px;">
          <router-link
            :to="{name: 'registration.signin'}"
            class="btn btn-white align-self-center me-auto mt-8 mr-3"
            style="color: #83C03B!important;border-color: #83C03B!important;"
          >
            Sign In
          </router-link>
          <router-link
            :to="{name: 'registration.signup'}"
            class="btn btn-primary align-self-center me-auto mt-8"
            style="background-color: #83C03B!important;border-color: #83C03B!important;"
          >
            Get Started Now
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Registration'
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 54px;
  line-height: 70px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 300;
}
h3 > strong {
  font-weight: bold;
}
a.btn-primary {
  background-color: #FF6600;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FF6600;
  box-sizing: border-box;
  border-radius: 35px;
}
a.btn-white {
  background-color: transparent;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 35px;
}
</style>
